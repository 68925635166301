/* eslint-disable import/no-mutable-exports */
var mediaItem = {
  init: function (matchHeight) {

  	$('.media-item').matchHeight();

  	$('.media-item-more').click(function(e){
  		e.preventDefault();
  		var container = $(this).data('container');
  		$('[data-more-container="'+container+'"]').children('.hidden').removeClass('hidden').addClass('opaque');
  		$('.media-item').matchHeight();
  		$(this).addClass('hidden');
		$('[data-more-container="'+container+'"]').children('.opaque').removeClass('opaque');
  	});

  }
};

export default mediaItem;