/* eslint-disable import/no-mutable-exports */
var mapMobilePanel = {
  init: function (stickybits) {

  	function openMobileMapPanel(){
	  	$('.vorhaben-mobile-menu-panel-map').addClass('active');
  	}
  	function closeMobileMapPanel(){
	  	$('.vorhaben-mobile-menu-panel-map').removeClass('active');
  	}
  	if( $('.vorhaben-mobile-menu-panel-map').length > 0 && $('.overview-left-panel--map').length > 0 ) {
	  	if( $(window).width() < 992 ) {
  			switchToMobileMode();
  		}

	  	function switchToMobileMode(){
	  		if( $(window).width() < 992 ) {
	  			if ($('.overview-map-wrapper-default .col:first-child .overview-left-panel--map').length > 0 ) {
			  		$('.vorhaben-mobile-menu-panel-map').append( $('.overview-left-panel--map') );
	
				  	//on trigger open mobile-map-mpanel
				  	$('.vorhaben-mobile-menu-panel-trigger .mobile-panel-trigger').click(function(e){
				  		e.preventDefault();
				  		openMobileMapPanel();
				  	});
				  	//on trigger close mobile-map-panel
				  	$('.vorhaben-mobile-menu-panel-map .mobile-panel-close').click(function(e){
				  		e.preventDefault();
				  		closeMobileMapPanel();
				  	});
					$('#customRightBox').removeClass('open');
			  	}
		  	}
	  	}
	  	function switchToDesktopMode(){
		  	if( $(window).width() > 991 ) {
	  			if ($('.vorhaben-mobile-menu-panel-map .overview-left-panel--map').length > 0 ) {
	  				$('.overview-map-wrapper-default > .col:first-child').append( $('.overview-left-panel--map') );
	  			}
	  			stickybits('.overview-left-panel', { useStickyClasses: true , stickyBitStickyOffset: 260 });
		  	}
	  	}

	  	//resize between viewports
	  	var resizeTimer = false;
	  	$(window).on('resize', function(e) {
	  	  if( !resizeTimer ) {
	  		$(window).trigger('resizestart');  	
	  	  }
	  	  clearTimeout(resizeTimer);
	  	  resizeTimer = setTimeout(function() {
	  		resizeTimer = false;
	  		$(window).trigger('resizeend');
	  	  }, 750);
	  	}).on('resizestart', function(){
	  	}).on('resizeend', function(){
		  	if( $(window).width() < 992 ) {
	  			switchToMobileMode();
	  		}else{
	  			switchToDesktopMode();
	  		}
	  	});

  	}
  	

  }
};

export default mapMobilePanel;