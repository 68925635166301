/* eslint-disable import/no-mutable-exports */
var mediathek = {
  init: function (matchHeight, lightbox2) {


    //mediathek Bilder/Publikationen match-Height
    $('.galleryOverview .item').matchHeight();
    $('.pdfListe .item').matchHeight();




  },
  videoLink: function () {

     // Start jqueryQueryParser //
    (function($) {
        var pl = /\+/g,
            searchStrict = /([^&=]+)=+([^&]*)/g,
            searchTolerant = /([^&=]+)=?([^&]*)/g,
            decode = function(s) {
                return decodeURIComponent(s.replace(pl, " "));
            };
        $.parseQuery = function(query, options) {
            var match,
                o = {},
                opts = options || {},
                search = opts.tolerant ? searchTolerant : searchStrict;
            if ('?' === query.substring(0, 1)) {
                query = query.substring(1);
            }
            while (match = search.exec(query)) {
                o[decode(match[1])] = decode(match[2]);
            }
            return o;
        };
        $.getQuery = function(options) {
            return $.parseQuery(window.location.search, options);
        };
        $.fn.parseQuery = function(options) {
            return $.parseQuery($(this).serialize(), options);
        };
    }(jQuery));
    // Ende jqueryQueryParser //




    //Start YTnocookie Functions
    function openYoutube(thisHref, e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        // Store the query string variables and values
        // Uses "jQuery Query Parser" plugin, to allow for various URL formats (could have extra parameters)
        var youTubeId = null;
        var queryString = thisHref.slice(thisHref.indexOf('?') + 1);
        var queryVars = $.parseQuery(queryString);
        var parser = document.createElement('a');
        parser.href = thisHref;
        // if GET variable "v" exists. This is the Youtube Video ID
        if ('v' in queryVars)
        {
            youTubeId = queryVars['v'];
            // console.log("qv: " + youTubeId);
        }
        else if (parser.hostname == 'www.youtube-nocookie.com')
        {
            youTubeId = parser.pathname.substring(2);
            if (youTubeId.indexOf("/") == 0)
                youTubeId = youTubeId.substring(1);
            // console.log("ph:" + youTubeId);
        }
        console.log("youtube id = " + youTubeId);
        if (null !== youTubeId)
        {
            // Prevent opening of external page
            e.preventDefault();
            // Variables for iFrame code. Width and height from data attributes, else use default.
            var vidWidth = $(document).width() - 20;
            if (vidWidth > 1024)
                vidWidth = 1024;
            var vidHeight = vidWidth * 576 / 1024;
            // console.log("size: " + vidWidth + ":" + vidHeight);
            if ($(this).attr('data-width')) {
                vidWidth = parseInt($(this).attr('data-width'));
            }
            if ($(this).attr('data-height')) {
                vidHeight = parseInt($(this).attr('data-height'));
            }
            var iFrameCode = '<iframe width="' + vidWidth + '" height="' + vidHeight + '" scrolling="no" allowtransparency="true" allowfullscreen="true" src="https://www.youtube-nocookie.com/embed/' + youTubeId + '?html5=1&rel=0&wmode=transparent&showinfo=0" frameborder="0"></iframe>';
            // console.log("Replace Modal HTML with iFrame Embed");
            $('#mediathekModal .modal-dialog').html(iFrameCode).css({
                'width': vidWidth + 'px'
            });
            // console.log("Set new width of modal window, based on dynamic video content");
            $('#mediathekModal').on('show.bs.modal', function() {
                // Add video width to left and right padding, to get new width of modal window
                var modalBody = $(this).find('.modal-body');
                var modalDialog = $(this).find('.modal-dialog');
                var newModalWidth = vidWidth + parseInt(modalBody.css("padding-left")) + parseInt(modalBody.css("padding-right"));
                newModalWidth += parseInt(modalDialog.css("padding-left")) + parseInt(modalDialog.css("padding-right"));
                newModalWidth += 'px';
                // console.log("Set width of modal (Bootstrap 3.0)");
                $(this).find('.modal-dialog').css('width', newModalWidth);
            });
            // console.log("Open Modal");
            $('#mediathekModal').modal();
            // console.log("Modal opened");
        }
    }
    //end YTnocookie


    function openHTML5Player(mp4Url){
      var vidWidth = $(document).width() - 20;
      if (vidWidth > 1024)
          vidWidth = 1024;
      var vidHeight = vidWidth * 576 / 1024;
      var iFrameCode = '<video width="' + vidWidth + '" height="'+ vidHeight +'" controls><source src="'+mp4Url+'" type="video/mp4" /></video>';
      // console.log("Replace Modal HTML with iFrame Embed");
      $('#mediathekModal .modal-dialog').html(iFrameCode).css({'width':vidWidth+'px'});
      // console.log("Set new width of modal window, based on dynamic video content");
      $('#mediathekModal').on('show.bs.modal', function () {
         // Add video width to left and right padding, to get new width of modal window
         var modalBody = $(this).find('.modal-body');
         var modalDialog = $(this).find('.modal-dialog');
         var newModalWidth = vidWidth + parseInt(modalBody.css("padding-left")) + parseInt(modalBody.css("padding-right"));
         newModalWidth += parseInt(modalDialog.css("padding-left")) + parseInt(modalDialog.css("padding-right"));
         newModalWidth += 'px';
         // console.log("Set width of modal (Bootstrap 3.0)");
          $(this).find('.modal-dialog').css('width', newModalWidth);
      });
      // console.log("Open Modal");
      $('#mediathekModal').modal();
    }

    //VideoLink Click
    $('a.videoLink').unbind( "click" ).on('click',function(e){
      console.log('huhu video click');
      e.preventDefault();
      var mp4Url = $(this).data('mp4');
      var href = $(this).attr('href');
      var isYTnocookie = false;
      var isYT = false;
      var isMedia = false;
      var hasMedia = false;


      if( href != undefined  ){
        if( href.startsWith("https://www.youtube-nocookie.com") ){
          isYTnocookie = true;
        }
        if( href.startsWith("http://youtu.be") ){
          isYT = true;
        }
        if( href.startsWith("https://data.netzausbau.de/Filme") ){
          isMedia = true;
        }
      }
      if( mp4Url != undefined  ){
        if( mp4Url.startsWith("https://data.netzausbau.de/Filme") ){
          hasMedia = true;
        }
      }


      if( isMedia ){
        openHTML5Player(href);
      }
      if( isYTnocookie ){

          //prepare Lightbox Content
          if( hasMedia ){
            var buttons = [
                      {
                          text: BUTTON_USEYOUTUBE,
                          class: 'btn btn-primary',//add BTN Class
                          click: function() {
                              openYoutube(linkTag, e);
                              dialog.dialog('close');
                          }
                      }, {
                          text: BUTTON_ALTERNATIVEVIDEOS,
                          class: 'btn btn-primary',//add BTN Class
                          click: function(e) {
                              // window.location.href = "/alternativeVideos";
                              e.stopPropagation();
                              e.stopImmediatePropagation();
                              e.preventDefault();

                              openHTML5Player(mp4Url);
                              dialog.dialog('close');
                          }
                      }, {
                          text: BUTTON_CLOSE,
                          class: 'btn btn-default',//add BTN Class
                          click: function() {
                              dialog.dialog('close');
                          }
                      }
                  ];
          }else{
             var buttons = [
                      {
                          text: BUTTON_USEYOUTUBE,
                          class: 'btn btn-primary',//add BTN Class
                          click: function() {
                              openYoutube(linkTag, e);
                              dialog.dialog('close');
                          }
                      }, {
                          text: BUTTON_CLOSE,
                          class: 'btn btn-default',//add BTN Class
                          click: function() {
                              dialog.dialog('close');
                          }
                      }
                  ];
          }

          // BOOTSTRAP 3.0 - Open YouTube Video Dynamicaly in Modal Window
          // Modal Window for dynamically opening videos
            $('.ui-dialog').remove();//remove existing dialog html to prevent multiple instances of the same
              var savedE = e;
              var linkTag = $(this).attr('href');
              var dialog = $('<p>' + MESSAGE_YOUTUBE + '</p>').dialog({
                  modal: true,

                  //always align in the center of screen
                  height: "auto",
              width: "auto",
              position: {
                  my: "center",
                  at: "center",
                  of: window
              },
                  buttons: buttons
              });

              return false;
      }



    });



   


    // Clear modal contents on close.
    // There was mention of videos that kept playing in the background.
    $('#mediathekModal').on('hidden.bs.modal', function() {
        // console.log("on hidden...");
        $('#mediathekModal .modal-dialog').html('');
        // console.log("on hidden end");
    });



    


  }//end Video Links




};

export default mediathek;