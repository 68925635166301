/* eslint-disable import/no-mutable-exports */
var navigation = {
  init: function () {


  var preventClose = false;

// SHOW-HIDE MENUES //
    $('.nav__link.menu').on('click', function (e) {
      e.preventDefault();
      	if( $(window).width() < 992 ) {
      		$('.menu-flyout-mobile').css('height',window.innerHeight - 120);
      	}
      	if( $(window).width() < 768 ) {
      		$('.menu-flyout-mobile').css('height',window.innerHeight - 82);
      	}

      	$(this).toggleClass('active');
      	$('body').toggleClass('hamburger-active');
      	$('.menu-flyout').toggleClass('active');
      	$('.menu-flyout-mobile .content').removeClass('active');
      	$('.menu-flyout-mobile .content').removeClass('inactive');
    });



// SHOW-HIDE Individual Menus on Hover //
		$('.nav .menu-container').bind('mouseover',function(event){
			preventClose = true;
			positionBottomMenu();
			if( $(window).width() > 991 ){
				// event.stopPropagation();
		      	if( !$(this).closest('.nav').hasClass('active') ){
		      		$('.main-navi .nav.active').removeClass('active');
		      		$('.nav__wrapper--main-bottom-left.active').removeClass('active');
		      		$('.nav__wrapper--main-bottom-left-default.active').removeClass('active');
					$(this).closest('.nav').addClass('active');

					var $icon = $(this).find('.menuTriggerIcon');
					$icon.attr('src',$icon.attr('data-src-hover'));

					var $current_menu = $(this).closest('.nav').data('menu');
					if($current_menu == 'main-bottom-left' || $current_menu == 'main-bottom-left-default'){
						$('.nav__wrapper--'+$current_menu).addClass('active');
					}
		      	}else{
					preventClose = true;
		      	}
		    }
		}).bind('mouseleave',function(){
			console.log('mouseleave MAIN');
			preventClose = false;
			if( $(window).width() > 991 ){
				event.stopPropagation();
				var $this = $(this);
				setTimeout(function(obj = $this){
					if(!preventClose){
						var $icon = obj.find('.menuTriggerIcon');
						if( $('.main-navi .nav.active').hasClass('on') ) {
							$icon.attr('src',$icon.attr('data-src-hover'));
						}else {
							$icon.attr('src',$icon.attr('data-src-default'));
						}
						$('.main-navi .nav').removeClass('active');
						$('.nav__wrapper--main-bottom-left').removeClass('active');
						$('.nav__wrapper--main-bottom-left-default').removeClass('active');
					}
				},250);
			}
		});


		$('.nav__wrapper--main-bottom-left').bind('mouseover',function(event){
			event.stopPropagation();
			preventClose = true;
		}).bind('mouseleave',function(event){
			event.stopPropagation();
			preventClose = false;
			setTimeout(function(){
				if(!preventClose){
					$('.main-navi .nav').removeClass('active');
					$('.nav__wrapper--main-bottom-left').removeClass('active');
				}
			},450);
		});

		$('.nav__wrapper--main-bottom-left-default').bind('mouseover',function(event){
			event.stopPropagation();
				preventClose = true;
		}).bind('mouseleave',function(event){
			event.stopPropagation();
				preventClose = false;
			setTimeout(function(){
				if(!preventClose){
					$('.main-navi .nav').removeClass('active');
					$('.nav__wrapper--main-bottom-left-default').removeClass('active');
				}
			},250);
		});






		// POSITIONING WITH FOOTER
		if( $(window).width() > 991 && $('.footer').length > 0 ){
		    document.addEventListener("scroll", function(){
		      positionBottomMenu();
		    });
			$(document).ready(function(){
				positionBottomMenu();
				setTimeout(function(){
					positionBottomMenu();

				},750);
			});
		}



		function positionBottomMenu() {
			if( $('.footer').length > 0){

			    var footer = document.querySelector('.footer');
				
				function getRectTop(el){
				  var rect = el.getBoundingClientRect();
				  return rect.top;
				}

				if( $('.nav--main-bottom-left-default').length > 0){
					var bottomLeftMenu = document.querySelector('.nav--main-bottom-left-default');
					
					if((getRectTop(bottomLeftMenu) + document.body.scrollTop) + bottomLeftMenu.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 10)
					  bottomLeftMenu.style.position = 'absolute';
					if(document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop))
					  bottomLeftMenu.style.position = 'fixed'; // restore when you scroll up
				}
				if( $('.nav__wrapper--main-bottom-left-default').length > 0 ){
			    	var bottomLeftSubMenu = document.querySelector('.nav__wrapper--main-bottom-left-default');
					
					if((getRectTop(bottomLeftSubMenu) + document.body.scrollTop) + bottomLeftSubMenu.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 10)
					  bottomLeftSubMenu.style.position = 'absolute';
					if(document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop))
					  bottomLeftSubMenu.style.position = 'fixed'; // restore when you scroll up
				}
			
			}
		}


		$( '.nav__wrapper--main-bottom-left-default .trigger-close-current-menu' ).click(function(){
			$('.nav__wrapper--main-bottom-left-default').removeClass('active');
			$('.nav--main-bottom-left-default').removeClass('active');
		});
		$( '.nav__wrapper--main-bottom-left .trigger-close-current-menu' ).click(function(){
			$('.nav__wrapper--main-bottom-left').removeClass('active');
			$('.nav--main-bottom-left').removeClass('active');
		});
		





		// Prevent Monitor link
//		$( '.nav.nav--main-top-right-default.on').find('.menu-trigger').removeAttr('href');
//		$( '.nav.nav--main-top-right-default.on').find('.menu-trigger').unbind('click');




		function resizeMenus(){
			if( $(window).innerHeight() < 840) {
				$('.main-navi--home > .nav').addClass('extraSmall');
			}else{
				$('.main-navi--home > .nav').removeClass('extraSmall');
			}
		}

		$(document).ready(function(){
	  		resizeMenus();
	  	});


	  	//resize between viewports
	  	var resizeTimer = false;
	  	$(window).on('resize', function(e) {
	  	  if( !resizeTimer ) {
	  		$(window).trigger('resizestart');  	
	  	  }
	  	  clearTimeout(resizeTimer);
	  	  resizeTimer = setTimeout(function() {
	  		resizeTimer = false;
	  		$(window).trigger('resizeend');
	  	  }, 750);
	  	}).on('resizestart', function(){
	  	}).on('resizeend', function(){
		  	if( $(window).width() < 992 ) {
	  			resizeMenus();
	  		}else{
	  			resizeMenus();
	  		}
	  	});




// IF SCROLLED TO TOP //
//    $(window).on('scroll', function () {
//      if ($(this).scrollTop() > 50) {
//        $('body').addClass('scrolled');
//      } else {
//        $('body').removeClass('scrolled');
//      }
//    });
  }
};

export default navigation;