import $ from 'jquery';

import 'es6-promise/auto';
//import 'regenerator-runtime/runtime';
//import 'whatwg-fetch';
// import './dependencies/jquery/jquery-3.5.0.min';

import './dependencies/jquery-ui/jquery-ui.min';

//import Libs
import stickybits from 'stickybits';
import matchHeight from 'jquery-match-height';
import selectpicker from 'bootstrap-select';
import scrollTo from 'jquery.scrollto';
import daterangepicker from 'daterangepicker';
import moment from 'moment';
import lightbox2 from 'lightbox2';


import glossar from '../../components/00-Utils/glossar';
import ajax from '../../components/00-Utils/ajax';
import init from '../../components/00-Utils/init';
import dateTime from '../../components/01-Atoms/date/date';
import searchInput from '../../components/01-Atoms/forms/search/search';
import searchform from '../../components/01-Atoms/forms/searchform/searchform';
import tooltip from '../../components/01-Atoms/tooltip/tooltip';
import navigation from '../../components/02-Molecules/navigation/navigation';
import gototop from '../../components/02-Molecules/gototop/gototop';
import progress from '../../components/02-Molecules/progress/progress';
import bitv from '../../components/02-Molecules/bitv/bitv';
import header from '../../components/03-Organisms/header/header';
import footer from '../../components/03-Organisms/footer/footer';
import menuFlyoutDesktop from '../../components/03-Organisms/menus/menu-flyout-desktop/menu-flyout-desktop';
import menuFlyoutMobile from '../../components/03-Organisms/menus/menu-flyout-mobile/menu-flyout-mobile';
import overviewLeftPanel from '../../components/03-Organisms/panels/overview-left-panel/overview-left-panel';
import overviewRightPanel from '../../components/03-Organisms/panels/overview-right-panel/overview-right-panel';
import overviewTopPanel from '../../components/03-Organisms/panels/overview-top-panel/overview-top-panel';
import overviewMobilePanel from '../../components/03-Organisms/panels/overview-mobile-panel/overview-mobile-panel';
import mapMobilePanel from '../../components/03-Organisms/panels/map-mobile-panel/map-mobile-panel';
import defaultMobilePanel from '../../components/03-Organisms/panels/default-mobile-panel/default-mobile-panel';
import monitorTopPanel from '../../components/03-Organisms/panels/monitor-top-panel/monitor-top-panel';
import monitorNavigation from '../../components/03-Organisms/panels/monitor-navigation/monitor-navigation';
import monitorFilter from '../../components/03-Organisms/panels/monitor-filter-panel/monitor-filter';
import monitorMobilePanel from '../../components/03-Organisms/panels/monitor-mobile-panel/monitor-mobile-panel';
// import monitorFilter from '../../components/03-Organisms/forms/monitor-filter/monitor-filter';
import listItem from '../../components/03-Organisms/modules/list-item/list-item';
import listFilter from '../../components/03-Organisms/modules/list-filter-example/list-filter-example';
import twitter from '../../components/03-Organisms/modules/twitter/twitter';
import contentMetaIcons from '../../components/03-Organisms/modules/content-meta-icons/content-meta-icons';
import monitorWidget from '../../components/03-Organisms/modules/monitor-widget/monitor-widget';
import mediaItem from '../../components/03-Organisms/modules/media-item/media-item';
import scrollspy from '../../components/03-Organisms/modules/scrollspy/scrollspy';
import progressPanel from '../../components/03-Organisms/modules/progress-panel/progress-panel';
import videoTeaser from '../../components/03-Organisms/modules/video-teaser/video-teaser';
import progressItem from '../../components/03-Organisms/modules/progress-item/progress-item';
import vorhaben from '../../components/05-Pages/vorhaben-overview/vorhaben-overview';
import vorhabenMap from '../../components/05-Pages/vorhaben-overview-map-default/vorhaben-overview-map';
import homepage from '../../components/05-Pages/homepage/homepage';
import monitor from '../../components/05-Pages/monitor/monitor';
import mediathek from '../../components/05-Pages/Mediathek/mediathek';

//Load Isotope PlugIn and make it available to jQuery via jquery-bridget
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');

// make Isotope a jQuery plugin
jQueryBridget('isotope', Isotope, $);

global.$ = $;
global.jQuery = $;


const BNETZA = {
  glossar: glossar,
  ajax: ajax,
  init: init,
  dateTime: dateTime,
  searchInput: searchInput,
  searchform: searchform,
  tooltip: tooltip,
  navigation: navigation,
  gototop: gototop,
  progress: progress,
  bitv: bitv,
  header: header,
  footer: footer,
  menuFlyoutDesktop: menuFlyoutDesktop,
  menuFlyoutMobile: menuFlyoutMobile,
  overviewLeftPanel: overviewLeftPanel,
  overviewRightPanel: overviewRightPanel,
  overviewTopPanel: overviewTopPanel,
  overviewMobilePanel: overviewMobilePanel,
  mapMobilePanel: mapMobilePanel,
  defaultMobilePanel: defaultMobilePanel,
  monitorFilter: monitorFilter,
  monitorTopPanel: monitorTopPanel,
  monitorMobilePanel: monitorMobilePanel,
  listItem: listItem,
  listFilter: listFilter,
  twitter: twitter,
  contentMetaIcons: contentMetaIcons,
  monitorWidget: monitorWidget,
  monitorNavigation: monitorNavigation,
  mediaItem: mediaItem,
  scrollspy: scrollspy,
  progressPanel: progressPanel,
  progressItem: progressItem,
  videoTeaser: videoTeaser,
  homepage: homepage,
  monitor: monitor,
  mediathek: mediathek,
  vorhaben: vorhaben,
  vorhabenMap: vorhabenMap
};

$(document).ready(function () {
  $('.selectpicker').selectpicker();
  BNETZA.init.init();
  BNETZA.glossar.init();
  BNETZA.ajax.init();
  BNETZA.dateTime.init(daterangepicker, moment);
  BNETZA.searchInput.init();
  BNETZA.searchform.init();
  BNETZA.tooltip.init();
  BNETZA.navigation.init();
  BNETZA.gototop.init(scrollTo);
  BNETZA.progress.init();
  BNETZA.bitv.init();
  BNETZA.header.init();
  BNETZA.footer.init();
  BNETZA.menuFlyoutDesktop.init();
  BNETZA.menuFlyoutMobile.init();
  BNETZA.overviewLeftPanel.init(stickybits);
  BNETZA.overviewRightPanel.init(stickybits, selectpicker, BNETZA);
  BNETZA.overviewTopPanel.init(stickybits);
  BNETZA.overviewMobilePanel.init(stickybits);
  BNETZA.mapMobilePanel.init(stickybits);
  BNETZA.defaultMobilePanel.init();
  BNETZA.listItem.init(matchHeight);
  BNETZA.listFilter.init(matchHeight);
  BNETZA.twitter.init(matchHeight);
  BNETZA.contentMetaIcons.init();
  BNETZA.monitorTopPanel.init();
  BNETZA.monitorWidget.init();
  BNETZA.monitorNavigation.init(stickybits, moment, BNETZA, matchHeight);
  BNETZA.monitorFilter.init();
  BNETZA.monitorMobilePanel.init(stickybits);
  BNETZA.mediaItem.init(matchHeight);
  BNETZA.scrollspy.init(scrollTo, stickybits);
  BNETZA.progressPanel.init(stickybits);
  BNETZA.progressItem.init();
  BNETZA.videoTeaser.init();
  BNETZA.homepage.init();
  BNETZA.monitor.init();
  BNETZA.mediathek.init(matchHeight, lightbox2);
  BNETZA.mediathek.videoLink();
  BNETZA.vorhaben.init();
  BNETZA.vorhabenMap.init();
});