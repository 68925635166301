/* eslint-disable import/no-mutable-exports */
var searchform = {
  init: function () {

  	$('.search-submit-button').click(function(e){
  		e.preventDefault();
  		var targetURL = $(this).closest('form').attr('data-target')
  		var searchString = $(this).siblings('[name="searchQueryString"]').val();
  		targetURL = targetURL + '?cms_search='+searchString;
  		window.location.href = targetURL;
  	})
  }
};

export default searchform;