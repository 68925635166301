/* eslint-disable import/no-mutable-exports */
var dateTime = {
    init: function (daterangepicker, moment) {


        if( $('.input-date-range').length > 0 ) {

            if( $('.input-date-range').data('locale') == 'de' ) { //locale `DE` picker
                moment.locale('de');
                // var $startdate = moment().subtract(29, 'days').toDate();
                // var $enddate = moment().toDate();
                // $('.filter-date .input-date-range').attr('data-startDate', moment($startdate).format('DD/MM/YYYY'));
                // $('.filter-date .input-date-range').attr('data-endDate', moment($enddate).format('DD/MM/YYYY'));
                //$('[name="monitor-filter-startdate"]').val(moment($startdate).format('DD/MM/YYYY'));
                //$('[name="monitor-filter-enddate"]').val(moment($enddate).format('DD/MM/YYYY'));
                

                var $startdate = $('.filter-date .input-date-range').attr('data-startDate');
                var $enddate = $('.filter-date .input-date-range').attr('data-endDate');

                if( $startdate =='' || $enddate == ""){
                    $startdate = moment().subtract(29, 'days').toDate();
                    $enddate = moment().toDate();  
                    $('[name="monitor-filter-startdate"]').val(moment($startdate).format('DD/MM/YYYY'));
                    $('[name="monitor-filter-enddate"]').val(moment($enddate).format('DD/MM/YYYY'));
                }

                console.log($startdate);
                console.log($enddate);
                $('[name="monitor-filter-startdate"]').val($startdate);
                $('[name="monitor-filter-enddate"]').val($enddate);



                $('.input-date-range').daterangepicker({
                    opens: 'left',
                    timePicker: false,
                    startDate: $startdate,
                    endDate: $enddate,
                    ranges: {
                        'Heute': [moment(), moment()],
                        'Letzte 7 Tage': [moment().subtract(6, 'days'), moment()],
                        'Letzte 30 Tage': [moment().subtract(29, 'days'), moment()],
                        'Letzte 12 Monate': [moment().subtract(12, 'month'), moment()],
                        'Seit Anfang': [moment("2012-01-01"), moment()]
                    },
                    locale: {
                            format: "DD/MM/YYYY",
                            separator: " - ",
                            applyLabel: "Bestätigen",
                            cancelLabel: "Abbrechen",
                            fromLabel: "Von",
                            toLabel: "Bis",
                            customRangeLabel: "Benutzerdefiniert",
                            weekLabel: "W",
                            daysOfWeek: [
                                "So",
                                "Mo",
                                "Di",
                                "Mi",
                                "Do",
                                "Fr",
                                "Sa"
                            ],
                            monthNames: [
                                "Januar",
                                "Februar",
                                "März",
                                "April",
                                "Mai",
                                "Juni",
                                "Juli",
                                "August",
                                "September",
                                "Oktober",
                                "November",
                                "Dezember"
                            ],
                            firstDay: 1
                        },
                });


            }else{
                $('.input-date-range').daterangepicker({ //locale `EN` picker
                    opens: 'left',
                    startDate: moment().subtract(29, 'days').toDate(),
                    endDate: moment().toDate(),
                    ranges: {
                        'Today': [moment(), moment()],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'Last 12 Months': [moment().subtract(12, 'month'), moment()],
                        'From Begin': [moment("2012-01-01"), moment()]
                    },
                });
            }



        }


    }
};
export default dateTime;