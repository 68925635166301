/* eslint-disable import/no-mutable-exports */
var ajax = {
    init: function () {

      $( document ).ajaxStart(function() {

      	if( $('#spinner').length < 1 ) {
      		$('body').append('<div id="spinner-overlay" class="spinner"></div><div id="spinner" class="spinner"><div class="fa-5x"><i class="fas fa-sync fa-spin"></i></div></div>');
      	}
      	$('.spinner').addClass('show');

      });

      $( document ).ajaxStop(function() {
      	$('.spinner').removeClass('show');
      });

    }
};
export default ajax;