/* eslint-disable import/no-mutable-exports */
var header = {
  init: function () {

	// IF page SCROLLED //
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('.header').addClass('is-scrolled');
        $('body').addClass('is-scrolled');

        if ( $('.wrapper-menu').length > 0  ){
          $('.wrapper-menu').addClass('is-scrolled');
        }
      } else {
        $('.header').removeClass('is-scrolled');
        $('body').removeClass('is-scrolled');

        if ( $('.wrapper-menu').length > 0  ){
          $('.wrapper-menu').removeClass('is-scrolled');
        }
      }
    });

  }
};

export default header;