/* eslint-disable import/no-mutable-exports */
var glossar = {
    init: function () {

        /* Start Addon_GlossarPopup */
        (function($) {

        function popupParams (w,h,switches,xpos,ypos) {
           var width = "width=" + w;
           var height = ",height=" + h;
           var parent = ",dependent=no";
           var dirbar = ",directories=no";
           var fullscreen = ",fullscreen=0";
           var hotkeys = ",hotkeys=yes";
           var locbar = ",location=no";
           var menubar = ",menubar=no";
           var resizable = ",resizable=no";
           var scrollbars = ",scrollbars=no";
           var statusbar = ",status=no";
           var toolbar = ",toolbar=no";

           if (switches) {
              if (switches.indexOf("p") > -1 ) { parent = ",dependent=yes"}
              if (switches.indexOf("d") > -1 ) { dirbar = ",directories=yes"}
              if (switches.indexOf("h") > -1 ) { hotkeys = ",hotkeys=no"}
              if (switches.indexOf("l") > -1 ) { locbar = ",location=yes"}
              if (switches.indexOf("m") > -1 ) { menubar = ",menubar=yes"}
              if (switches.indexOf("r") > -1 ) { resizable = ",resizable=yes"}
              if (switches.indexOf("s") > -1 ) { scrollbars = ",scrollbars=yes" }
              if (switches.indexOf("u") > -1 ) { statusbar = ",status=yes"}
              if (switches.indexOf("t") > -1 ) { toolbar = ",toolbar=yes"}
              if (switches.indexOf("k") > -1 || switches.indexOf("b") > -1 ) { fullscreen = ",fullscreen=1" }
           }
           var pos = "";
           return width + height + parent + dirbar + hotkeys + locbar + menubar + resizable + scrollbars + statusbar + toolbar + pos + fullscreen;
        }

        var newwin; var w = 420; var h = 700; var switches;
        var params = popupParams(w,h,switches);
        jQuery.init_glossarylink = function() {
            // baseurl = $('base').attr('href'); // for LIVE
            // var baseurl = "http://netzausbau.ddns.net/";
            $('.GlossarEntry').removeAttr('onclick').click(function(e){
                e.preventDefault();
                // var url = baseurl+$(this).attr('href');
                var url = $(this).attr('href');
                // newwin = window.open(url,"popup", params);   
                // return false;

                var minWidth = '300';
                $.ajax({
                  url: url,
                  dataType: 'html',
                  success: function(data){
                    data = '<button type="button" class="close" data-dismiss="modal" aria-hidden="true"><span class="svg-icon svg-icon-close"></span></button>'+data;
                    $('#glossarModal .modal-dialog').html(data).css({'min-width':minWidth+'px'});
                    $('#glossarModal').modal();
                    jQuery.init_glossarylink();
                    $('#navFunctionsClose > a').click(function(e){
                      e.preventDefault();
                      e.stopImmediatePropagation();
                      $('#glossarModal').modal('hide');
                    });

                  }
                });


            });
        };

        jQuery.setGlossaryNav = function(){   
             $('#popup a').click(function(){
               opener.location.href = $(this).attr('href');
               window.close();
               return false;
             });
            $('#navFunctionsClose a').unbind('click').click(function(){
               window.close();
               return false;
             });
          };

        jQuery('document').ready(function(){
            $.setGlossaryNav();
        });
        })(jQuery);
        /* Ende Addon_GlossarPopup */



        jQuery(document).ready(function (){
            $ .init_glossarylink();
        });

    }
};
export default glossar;