/* eslint-disable import/no-mutable-exports */
var contentMetaIcons = {
  init: function (matchHeight) {
    var curURL = window.location.href;

  	$('.content-meta-social-icons-close').click(function(e){
  		e.preventDefault();
  		$(this).parent('.content-meta-social-icons').toggleClass('hidden');
  		$(this).parent().parent('.content-meta-container').children('.content-meta-icons').children('.meta-share').toggleClass('active');
  	});
  	$('.meta-share').click(function(e){
  		e.preventDefault();
  		$(this).toggleClass('active');
  		$(this).parent().parent('.content-meta-container').children('.content-meta-social-icons').toggleClass('hidden');
  	});


    $('.meta-print').click(function(e){
      e.preventDefault();
      window.print();
    });
    $('.share-email').click(function(e){
      e.preventDefault();
      var message = $(this).attr('data-message');
      var subject = $(this).attr('data-subject');
      window.location = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(message);

    });
    $('.share-facebook').click(function(e){
      e.preventDefault();
      var shortId = $('.meta-shortlink').attr('data-link');
      if(shortId != undefined){
        var shortURL = location.protocol + "//" + location.host + "/" + shortId;
      }else{
        shortURL = curURL;
      }
      // window.open('https://www.facebook.com/sharer.php?u=' + encodeURIComponent(curURL),'_blank');
      window.open('https://www.facebook.com/sharer.php?u=' + shortURL,'_blank');
    });
    $('.share-twitter').click(function(e){
      e.preventDefault();
      var shortId = $('.meta-shortlink').attr('data-link');
      if(shortId != undefined){
        var shortURL = location.protocol + "//" + location.host + "/" + shortId;
      }else{
        shortURL = curURL;
      }
      // window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(curURL),'_blank');
      window.open('https://twitter.com/intent/tweet?url=' + shortURL,'_blank');
    });
    $('.meta-shortlink').click(function(e){
      e.preventDefault();
      var shortId = $(this).attr('data-link');
      var msg;
      if(COPYURL == undefined){
        msg = 'Kurzlink in der Zwischenablage kopiert:';
      }else{
        msg = COPYURL;
      }
      if(shortId != undefined){
        var shortURL = location.protocol + "//" + location.host + "/" + shortId;
        if( $('#shortLinkModal').length > 0 ){
          $('#shortLinkModal').remove();
        }
        $('body').append('<input id="clipboard_copytext" value="'+shortURL+'" style="">');
        $('body').append('<div id="shortLinkModal" class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="shortLinkModal" aria-hidden="true"><div class="modal-dialog modal-sm"><div class="modal-content">'+msg+' <span id="clipboard_copytext">'+shortURL+'</span></div></div></div>');
        $('#shortLinkModal').modal('show');
        var copyText = document.getElementById("clipboard_copytext");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");

        $('#shortLinkModal').on('hidden.bs.modal', function () {
            if( $('#clipboard_copytext').length > 0 ){
              $('#clipboard_copytext').remove();
            }
        });
      }
    });

  }
};

export default contentMetaIcons;