/* eslint-disable import/no-mutable-exports */
var gototop = {
  init: function (scrollTo) {
  	var scrolPos;
 		$(window).on('scroll',function(){
	  		scrolPos = $(window).scrollTop();
	  		if(scrolPos > 200) {
	  			if( $('.gototop').hasClass('hidden') ){
	  				$('.gototop').removeClass('hidden');
	  			}
	  		}else{
	  			if( !$('.gototop').hasClass('hidden') ){
		  			$('.gototop').addClass('hidden');
		  		}
	  		}
 		});

 		$('.gototop').on('click',function(e){
 			e.preventDefault();
 			//Old scrollIntoView Function
	        //$('#main')[0].scrollIntoView({
	        //   behavior: "smooth"
	        //});
	        //new ScrollTo PlugIn
	        $.scrollTo(0, 400, {
			     offset: 0
			});


	        return false;
 		});

 		//Anchor Links
 		$('[href^="#"].link-anchor').on('click',function(e){
 			e.preventDefault();
 			var $anchor = $(this).attr('href');
 			$anchor = $anchor.substring(1);

	        var $offset = -90;
	        if($(document).width() > 768){
	        	$offset = -110;
	        }
	        if($(document).width() > 992){
	        	$offset = -200;
	        }

	        $.scrollTo($('#'+$anchor), 400, {
			     offset: $offset
			});

	        return false;
 		});
  }
};

export default gototop;