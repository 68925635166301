/* eslint-disable import/no-mutable-exports */
var listItem = {
  init: function (matchHeight) {
  	$('.list-item--box').matchHeight();

  	if( $('[data-more-container]').length > 0 ){
  		$('[data-more-container]').each(function(){
  			var maxShown = $(this).data('show-max');

  			$(this).children('.col').addClass('hidden');

  			$(this).children('.col').each(function(index){
  				if( index < maxShown){
  					$(this).removeClass('hidden');
  				}
  			})
  		});


      if( $('.monitor-widget > .col.hidden').length < 1 ) {
        $('.list-item-more').addClass('hidden');
      }


  	}

  	$('.list-item-more').click(function(e){
  		e.preventDefault();
  		var container = $(this).data('container');
  		$('[data-more-container="'+container+'"]').children('.hidden').removeClass('hidden').addClass('opaque');
      $('.list-item--box').matchHeight();
  		$(this).addClass('hidden');
 		  $('[data-more-container="'+container+'"]').children('.opaque').removeClass('opaque');
  	});


    if( $('.monitor-widget').length > 0 ) {
      if( $('.monitor-widget').children('.hidden').length < 1 ) {
        $('.filter-bottom').find('.list-item-more').addClass('hidden');
      }
    }



  }
};

export default listItem;