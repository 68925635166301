/* eslint-disable import/no-mutable-exports */
var menuFlyoutDesktop = {
  init: function () {

    var searchtext = '';
    var loop = 0;
  	
    function makeVisible(name) {
      $('.quicknav-list').removeClass('active');
      $('.quicknav-list.'+name).addClass('active');
    }

    $('[name="radioQuickVorhaben"]').change(function(){
      makeVisible($(this).val());
      $('.quicksearch').val("");
      $('.quicknav-list > li').removeClass( 'not-shown' );
    });


    $('.quicknav-wrapper .pagination-simple .forward a').click(function(e){
      e.preventDefault();
       var curIndex = $('.quicknav-list.active > li.active').index();
       var maxIndex = $('.quicknav-list.active > li').length;
       maxIndex = maxIndex - 1;

       if( curIndex < maxIndex ) {
         var nextIndex = curIndex + 1;
       }else{
         var nextIndex = 0;
       }

       var $i = 0;
       while( $('.quicknav-list.active > li:eq('+nextIndex+')').hasClass('not-shown') && $i < 100){
         if( curIndex < maxIndex ) {
          nextIndex = nextIndex + 1;
         }else{
          nextIndex = 0;
         }
         curIndex = nextIndex;
         $i++;
       }
       $('.quicknav-list.active > li.active').removeClass('active');
       $('.quicknav-list.active > li:eq('+nextIndex+')').addClass('active');
    });

    $('.quicknav-wrapper .pagination-simple .back a').click(function(e){
       e.preventDefault();
       var curIndex = $('.quicknav-list.active > li.active').index();
       var maxIndex = $('.quicknav-list.active > li').length;
       maxIndex = maxIndex - 1;
       if( curIndex > 0 ) {
        var nextIndex = curIndex - 1;
       }else{
        var nextIndex = maxIndex;
       }
       var $i = 0;
       while( $('.quicknav-list.active > li:eq('+nextIndex+')').hasClass('not-shown') && $i < 100){
         if( curIndex > 0 ) {
          var nextIndex = curIndex - 1;
         }else{
          var nextIndex = maxIndex;
         }
         curIndex = nextIndex;
         $i++;
       }
       $('.quicknav-list.active > li.active').removeClass('active');
       $('.quicknav-list.active > li:eq('+nextIndex+')').addClass('active');
    });

    $('.quicksearch').keydown(function(e){
      $('.quicksearch').trigger('change');
      if (e.keyCode == 13) {
          searchtext = $(this).val();
          filterQuicksearch(searchtext);
      }else {
        $('.quicksearch').trigger('change');
        setTimeout(() => {
          searchtext = $(this).val();
          filterQuicksearch(searchtext);
        }, 0);
      }
    });

    function filterQuicksearch(searchtext){
        searchtext = searchtext.toLowerCase();

        $('.quicknav-list.active > li').removeClass('not-shown');
        $('.quicknav-list.active > li').removeClass('active');
        var firstflag = true;
        $('.quicknav-list.active > li').each(function(){
          var string = $(this).children('a').attr('title');
          string = string.toLowerCase();

          if(  string.indexOf(searchtext) == -1) {
            $(this).addClass('not-shown');
          }else {
            if( firstflag ){
              $(this).addClass('active');
              firstflag = false;
            }
          }
        });
    }

  }
};

export default menuFlyoutDesktop;