/* eslint-disable import/no-mutable-exports */
var listFilter = {
  init: function (matchHeight) {

	if( $('.filter-target-container').length > 0 ) {
  		//activate isotope for all available filter-groups
		$('.filter-target-container').each(function(){
			var name = $(this).data('name');
			var items = $(this).data('items');

		  	$(name).isotope({
			    // options
			    itemSelector: '.col',
   			    transitionDuration: 0,
   			    layoutMode: 'fitRows'


//v2			    transitionDuration: 350,
//v2			    layoutMode: 'fitRows',
//v2			    hiddenStyle: {
//v2				  opacity: 0,
//v2				  transform: 'scale(1)'
//v2				},
//v2				visibleStyle: {
//v2				  opacity: 1,
//v2				  transform: 'scale(1)'
//v2				},
//v2				stagger: 150
			});
		});
	}

 	$('.list-filter:not(.list-filter-vertical) > a.show-terminated').click(function(e){
 		e.preventDefault();
 		$(this).hide();
 		$(this).siblings('.terminated').removeClass('terminated');
 	});
 	//remove not existing result filter classes from Filter Menu
	$('.list-filter a').each(function(){
		if( $(this)[0].hasAttribute('data-filter') ){
			var filterClass = $(this).data('filter');
			if( $('.filter-target-container > div'+filterClass ).length < 1 ) {
				$(this).addClass('emptyResults');
			}
		}
	});

 	$('.list-filter:not(.list-filter-vertical) > a:not(.show-terminated)').click(function(e){
 		e.preventDefault();
 			var target = $(this).parent('.list-filter').data('target');
			var filter = $(this).data('filter');

			var more = $(target).data('more-container');
			if( !$('[data-container="'+more+'"]').hasClass('hidden') ) {
				$('[data-container="'+more+'"]').trigger('click');
		 		$('.list-item--box').matchHeight();

		  		$(this).parent().children('input').val(filter);

			  	setTimeout(function(){
			  		// convert object into array
		    		var isoFilters = [];
			  		$('[data-target="'+target+'"]').each(function(){
			  			isoFilters.push($(this).children('input').val());
			  		});
			  		var selector = isoFilters.join('');
			  		var $grid  = $(target).isotope({ 
				  			itemSelector: '.col', 
				  			filter: selector, 
				  			transitionDuration: 0,
				  			layoutMode: 'fitRows'

//v2				  			transitionDuration: 350,
//v2				  			layoutMode: 'fitRows',
//v2			  			    hiddenStyle: {
//v2			  				  opacity: 0,
//v2			  				  transform: 'scale(1)'
//v2			  				},
//v2			  				visibleStyle: {
//v2			  				  opacity: 1,
//v2			  				  transform: 'scale(1)'
//v2			  				},
//v2			  				stagger: 150
			  			});
			  			$('.list-item--box').matchHeight();

			 	},300);
				 	$(this).siblings().removeClass('active');
				 	$(this).addClass('active');

			}else {
		  		$(this).parent().children('input').val(filter);

		  		// convert object into array
	    		var isoFilters = [];
		  		$('[data-target="'+target+'"]').each(function(){
		  			isoFilters.push($(this).children('input').val());
		  		});
		  		var selector = isoFilters.join('');
		  		var $grid  = $(target).isotope({ 
							  			itemSelector: '.col', 
							  			filter: selector, 
							  			transitionDuration: 0,
   			    						layoutMode: 'fitRows'

//v2							  			transitionDuration: 350,
//v2							  			layoutMode: 'fitRows',
//v2						  			    hiddenStyle: {
//v2						  				  opacity: 0,
//v2						  				  transform: 'scale(1)'
//v2						  				},
//v2						  				visibleStyle: {
//v2						  				  opacity: 1,
//v2						  				  transform: 'scale(1)'
//v2						  				},
//v2						  				stagger: 150
		  						});

			 	$(this).siblings().removeClass('active');
			 	$(this).addClass('active');

			}

		 	return false;
 	});


  }
};

export default listFilter;