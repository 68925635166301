/* eslint-disable import/no-mutable-exports */
var monitorMobilePanel = {
  init: function (stickybits) {

  	function openMobileMonitorFilterPanel(){
	  	$('.monitor-mobile-menu-panel-filter').addClass('active');
  	}
  	function closeMobileMonitorFilterPanel(){
	  	$('.monitor-mobile-menu-panel-filter').removeClass('active');
  	}
  	
  	if( $('.monitor-mobile-menu-panel-filter').length > 0 && $('.monitor-filter-panel').length > 0 ) {

  		if( $(window).width() < 992 ) {
  			switchToMobileMode();
  		}
  		function switchToMobileMode(){
	  		if( $(window).width() < 992 ) {
		  		$('.monitor-mobile-menu-panel-filter').append( $('.monitor-navigation') );
		  		$('.monitor-mobile-menu-panel-filter').append( $('.monitor-filter-panel') );

			  	//on trigger open mobile-filter-mpanel
			  	$('.monitor-mobile-menu-panel-trigger .mobile-panel-trigger').click(function(e){
			  		e.preventDefault();
			  		openMobileMonitorFilterPanel();
			  	});
			  	//on trigger close mobile-filter-panel
			  	$('.monitor-mobile-menu-panel-filter .mobile-panel-close').click(function(e){
			  		e.preventDefault();
			  		closeMobileMonitorFilterPanel();
			  	});
			  	$('.monitor-mobile-menu-panel-filter .monitor-filter-panel .filter-submit').click(function(e){
			  		closeMobileMonitorFilterPanel();
			  	});
		  	}
  		}
  		function switchToDesktopMode(){
		  	if( $(window).width() > 991 ) {
	  			if ($('.monitor-mobile-menu-panel-filter .monitor-navigation').length > 0 ) {
	  				// $('.overview-list-wrapper > .col:first-child').append( $('.monitor-navigation') );
            $('.overview-list-wrapper > .col-lg-2').append( $('.monitor-navigation') );
	  			}
	  			if ($('.monitor-mobile-menu-panel-filter .monitor-filter-panel').length > 0 ) {
	  				$('.overview-list-wrapper > .col:last-child').append( $('.monitor-filter-panel') );
	  			}
	  			stickybits('.monitor-navigation', { useStickyClasses: true , stickyBitStickyOffset: 300 });
		  	}
	  	}

  	  	//resize between viewports
  	  	var resizeTimer = false;
  	  	$(window).on('resize', function(e) {
  	  	  if( !resizeTimer ) {
  	  		$(window).trigger('resizestart');  	
  	  	  }
  	  	  clearTimeout(resizeTimer);
  	  	  resizeTimer = setTimeout(function() {
  	  		resizeTimer = false;
  	  		$(window).trigger('resizeend');
  	  	  }, 750);
  	  	}).on('resizestart', function(){
  	  	}).on('resizeend', function(){
  		  	if( $(window).width() < 992 ) {
  	  			switchToMobileMode();
  	  		}else{
  	  			switchToDesktopMode();
  	  		}
  	  	});







  	}

  }
};

export default monitorMobilePanel;