/* eslint-disable import/no-mutable-exports */
var progressItem = {
  init: function () {
    
    $('.progress-verticalsimple > .content a[data-target]').click(function(e){
      e.preventDefault();
      var target = $(this).attr('data-target');

      $('.'+target).next().collapse('show');

      if($('.'+target).length > 0){

        var $offset = -90;
        if($(document).width() > 768){
          $offset = -110;
        }
        if($(document).width() > 992){
          $offset = -200;
        }

        $.scrollTo($('.'+target), 400, {
             offset: $offset
        });

      }

    });


   
  }
};

export default progressItem;