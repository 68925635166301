/* eslint-disable import/no-mutable-exports */
var monitorFilter = {
  init: function () {
        var totalResults = 0;
        var totalPages = 0;
        var currentPage = 0;

  			$('.filter-vorhaben [data-toggle="collapse"]').each(function() {
  				if( $(this).hasClass('active') ) {
  					$(this).addClass('active-filter');
  					$(this).removeAttr('data-toggle');
  				}
  			});
  			$('.filter-vorhaben [data-subcategory]').each(function() {
  				if( $(this).hasClass('active') ) {
  					$(this).addClass('active-filter');
  					$(this).closest('.collapse').addClass('active-filter') ;
  				}
  			});



        //SEARCH INPUT, RESULTS and Search Results Navigation
        function getTotalSearchResults() {
          totalResults = $('.search-filter-results-container').children().length;
          return totalResults;
        }
        
        function calculateTotalSearchResultsPages(totalResults) {
          totalPages = Math.ceil(totalResults / 3);
          return totalPages;
        }

        function initializeMonitorResults() {
          totalResults = getTotalSearchResults();
          totalPages = calculateTotalSearchResultsPages(totalResults);
          $('.search-filter-results-container >a:nth-child(n+4)').addClass('hidden');
          $('.search-filter-results-navigation >.current').text(1);
          $('.search-filter-results-navigation >.total').text(totalPages);
          $('.search-filter-results-navigation >.svg-icon-chevron-left').addClass('disabled');
          $('.search-filter-results-container').show();
          currentPage = 1;

        }
        
        function gotoNextPage() {
          if(currentPage<totalPages){
            currentPage = currentPage+1;
            showPage(currentPage);

            if(currentPage == totalPages) {
              //disable next Button
              disableNextBtn();
            }
            if(currentPage > 1) {
              //enable prev btn
              enablePrevBtn();
            }
          }else{
            //is last page
            disableNextBtn();
          }
        }
        function gotoPrevPage() {
          if(currentPage>1){
            currentPage = currentPage-1;
            showPage(currentPage);
            if(currentPage == 1) {
              //disable next Button
              disablePrevBtn();
            }
            if(currentPage < totalPages) {
              //enable prev btn
              enableNextBtn();
            }
          }else{
            //is last page
            disablePrevBtn();
          }
        }

        function disableNextBtn() {
          $('.search-filter-results-navigation .filter-nav-next').addClass('disabled');
        }
        function disablePrevBtn() {
          $('.search-filter-results-navigation .filter-nav-prev').addClass('disabled');
        }
        function enableNextBtn() {
          $('.search-filter-results-navigation .filter-nav-next').removeClass('disabled');
        }
        function enablePrevBtn() {
          $('.search-filter-results-navigation .filter-nav-prev').removeClass('disabled');
        }

        function showPage(pagenumber){
          $('.search-filter-results-container').children().addClass('hidden');//hide all
          //the math for the range
          var pagenumber0 = pagenumber - 1;
          var from = (pagenumber0 * 3) + 1; //Math: 3x+1
          var to = pagenumber * 3; //Math: 3x
          //show the next range
          $('.search-filter-results-container >a:nth-child(n+'+from+'):nth-child(-n+'+to+')').removeClass('hidden');
          $('.search-filter-results-navigation > .current').text(pagenumber);
        }


        $('.filter-nav-prev').click(function(e){
          e.preventDefault();
          gotoPrevPage();
        });
        $('.filter-nav-next').click(function(e){
          e.preventDefault();
          gotoNextPage();
        });

        initializeMonitorResults();


        //initialize or update monitor-search-results-container
        function initializeSearchResults(data) {
            if( data !== undefined) {
              $('.monitor-search-results-container').html(data);
            }

        }

        //search with predefined Value
        function initializePredefinedSearchResults(curValue) {
            //set input field
            $('[name="filtersearch"]').val(curValue);
            //send updated filters
            $('[name="filtersearch"]').trigger('change');

        }

        var searchTimeout;
        $('.filter-search > input').on('input', function(e){
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          clearTimeout(searchTimeout);
          var curValue = $(this).val();
          if( curValue.length > 2) {
            searchTimeout = setTimeout(initializePredefinedSearchResults, 750, curValue);
          }
        });


        $('.search-filter-results-container > a').click(function(e){
            e.preventDefault();
            var $predefinedValue = $(this).attr('data-value');
            initializePredefinedSearchResults($predefinedValue);
        });

  }
};

export default monitorFilter;