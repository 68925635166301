/* eslint-disable import/no-mutable-exports */
var monitorWidget = {
  init: function () {

    function getParameters(obj) {
      var urlParameter = '';
      obj.closest('.monitor-widget-container').find('.list-filter').each(function(index){
        var tmp = $(this).find('a.active').attr('data-filter');
        tmp = tmp.substring(1);
        if(tmp == 'col') tmp='all';
        if(index<1){
          urlParameter = '?filter'+index+'='+tmp;

        }else{
          urlParameter = urlParameter+'&filter'+index+'='+tmp;
        }
      });

      return urlParameter;
    }

//  	$('.monitor-widget-container .monitor-opener').click(function(e){
//  		e.preventDefault();
//
//  		var urlParameter = getParameters($(this));
//      console.log(urlParameter);
//
//      //redirect to page
//  		window.location.href = $(this).attr('href')+urlParameter; 
//
//  	})
  }
};

export default monitorWidget;