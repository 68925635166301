/* eslint-disable import/no-mutable-exports */
var scrollspy = {
  init: function (scrollTo, stickybits) {
      //ScrollSpy
      var hasScrollspy=0;
      var iScrollPos = 0;

      function updateScrollPos(){
          var iCurScrollPos = $(window).scrollTop();
          if (iCurScrollPos > iScrollPos) {
              if(iCurScrollPos > 107){
                 // $('#scrollspy').addClass("hiddenspy",500);
              }
          } else {
              if(iCurScrollPos < 80){
                 // $('#scrollspy').removeClass('hiddenspy');
              }
          }
          iScrollPos = iCurScrollPos;
      }



      function showScrollspy(){
        // cl("hasScrollspy "+hasScrollspy);
        if(hasScrollspy == 1){
          // $("#scrollspy").show(100);
        }
      }
      function initScrollSpy(){

        var ssfound=0;
        var ssul=$("#scrollspyul");
        $('ul.toc li').each(function(){
           if(ssfound==0){
             var text=$(".main-content h1.isFirstInSlot").text();
             var link=$("ul.toc li a").first().attr("href").split("#")[0]+"#Inhalt";
             var newLI=$("<li><a href='"+link+"' data-jump='Inhalt' title='"+text+"' class='active'><strong>"+text+"</strong></a></li>");
             ssul.append(newLI);
             $("#scrollspy").removeClass('d-none');
           }
           ssfound=1;
           // cl("found toc-li");
           var toca=$(this).find("a");
           var url=toca.attr("href");
           // cl($(this)+" = "+url);
           toca.attr("data-jump",url.split("#")[1]);
           $(this).children('a').attr('title',$(this).children('a').text());
           ssul.append($(this));
        });
        if(ssfound==1){
          hasScrollspy=1;
          showScrollspy();
          $('#scrollspy a').each(function(){
             $(this).on("click",function(e){
               // cl("click ul scrollspy");
               $("#scrollspy a.active").removeClass("active");
               $(this).addClass("active");
               e.preventDefault();
                var $anchor = $(this).attr('data-jump');
                var $offset = -90;
                if($(document).width() > 768){
                  $offset = -110;
                }
                if($(document).width() > 992){
                  $offset = -200;
                }
                $.scrollTo($('#'+$anchor), 400, {
                 offset: $offset
                });
             });
          });
        }
        // cl("inner width="+window.innerWidth);
        if(window.innerWidth<1700) {$("#scrollspy").toggleClass("hiddenspy");}

      }
      $(window).scroll(function () {
         updateScrollPos();
         //analyseScrollPosition;
         // cl("scroll position:"+iScrollPos);
         var screenHeight=window.innerHeight;
         // cl("screen height:"+screenHeight);
         var lastGoodA=0;
         $('#scrollspy a').each(function(){
           var datajump=$(this).attr("data-jump");
           // cl("analyse toc a"+datajump);
           var ajump=$("#"+datajump);
           if(ajump.length > 0){
             var aOffsetTop=ajump.offset().top
             // cl("offset  top"+aOffsetTop);
             if(aOffsetTop<iScrollPos+200){lastGoodA=$(this);}
           }
         });
         if(lastGoodA!=0){
           $("#scrollspy a.active").removeClass("active");
             lastGoodA.addClass("active");
         }
      });
      //ScrollSpy Ende

      if(window.innerWidth<1700) $("#scrollspy").addClass('hiddenspy');


      // stickybits('#scrollspy', { useStickyClasses: true , stickyBitStickyOffset: 200 });

      initScrollSpy();


      $("#scrollspy .button").click(function(e){
        e.preventDefault();
        $("#scrollspy").toggleClass("hiddenspy");
      });



  }
};

export default scrollspy;



