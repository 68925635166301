/* eslint-disable import/no-mutable-exports */
var searchInput = {
  init: function () {
  			$('.input-group-search > input.form-control').on('input',function(e){
         if( $(this).val() == '' ) {
          $(this).parent().children('.svg-icon-search').show();
          $(this).parent().children('.svg-icon-close').hide();
         }else {
          $(this).parent().children('.svg-icon-search').hide();
          $(this).parent().children('.svg-icon-close').show();
         }

        });
        $('.input-group-search > .svg-icon-close').click(function(e){
          e.preventDefault();
          $(this).parent().children('input').val('');
          $(this).hide();
          $(this).parent().children('.svg-icon-search').show();
        });
  }
};

export default searchInput;