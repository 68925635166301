/* eslint-disable import/no-mutable-exports */
var bitv = {
  init: function () {

  	$('.navSkip a[href*="#Inhalt"]').click(function(e){
  		e.preventDefault();

  		var $offset = -90;
  		if($(document).width() > 768){
  		  $offset = -110;
  		}
  		if($(document).width() > 992){
  		  $offset = -200;
  		}
  		$.scrollTo($('#Start'), 400, {
  		 offset: $offset
  		});

  	});
  	$('.navSkip a[href*="#Hauptmenu"]').click(function(e){
  		e.preventDefault();
  		$('.nav__link.menu').trigger('click');
  		$('#firstMenuLink').focus();

  	});
  	$('.navSkip a[href*="#Suche"]').click(function(e){
  		e.preventDefault();
  		$('.header [name="searchQueryString"]').focus();
  	});


  }
};

export default bitv;