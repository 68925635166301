/* eslint-disable import/no-mutable-exports */
var vorhaben = {
  init: function () {






  	if( $('.overview-right-panel').length > 0 ){

//	  	var isMobile = false;
//	  	var breakpoints = [992];
//
//	  	if( $(window).width() < breakpoints[0] ){
//	    }
//
//		$(window).resize(function(){
//		    if( $(window).width() < breakpoints[0] ){
//		    	if( isMobile == false ){
//		    		//do content move
//		    		
//		    		isMobile = true
//
//		    	}
//		    }
//		});

  	}


  }
};

export default vorhaben;