/* eslint-disable import/no-mutable-exports */
var monitorNavigation = {
  init: function (stickybits, moment, BNETZA, matchHeight) {
    const stickyNav  = stickybits('.monitor-navigation', { useStickyClasses: true , stickyBitStickyOffset: 240 });
    var $category = '';
    var $subcategory = '';
    var $sort = '';
    var $ascdesc = '';
    var $filterMedia = '';
    var $v_category = '';
    var $v_subcategory = '';
    var $startdate = '';
    var $enddate = '';
    var $search = '';
    var $searchPage = '1';
    var $monitorPage = '1';
    var $pageFlag = false;
    var $totalCount = 0;


    window.addEventListener('resize', () => {
      stickyNav.update();
    });

    window.addEventListener('hashchange', () => {
      stickyNav.update();
    });



    function getUrlParameter(sParam) {
        var sURLVariables = window.location.search.substring(1).split('&'), sParameterName, i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    }

    var url = window.location.href;
    var urlHasParams = false;
    if(url.includes('?')){
      urlHasParams = true;
    }


    //FILTER over URL Search Parameter
    var $searchURLParam = getUrlParameter('cms_search');
    if( $searchURLParam != ''){
      $('[name="filtersearch"]').val($searchURLParam);
    }

    //FILTER over URL Media Parameter
    var $mediaURLParam = getUrlParameter('cms_media');
    if( $mediaURLParam != ''){

      $('[name="monitor-filter-category"]').val("publications");
      $('[name="monitor-filter-subcategory"]').val($mediaURLParam);
      $('.monitor-navigation [data-category].active').removeClass('active');
      $('.monitor-navigation [data-subcategory="' + $mediaURLParam + '"]').addClass('active');

      //$('[name="monitor-filter-media"]').val($mediaURLParam);
      //$('.filter-items-container [data-filter]').removeClass('active');
      //$('.filter-items-container [data-filter="'+$mediaURLParam+'"]').addClass('active');
    }


    //FILTER over URL startDate
    var $startdateURLParam = getUrlParameter('cms_startdate');
    if( $startdateURLParam != undefined && $startdateURLParam != ''){
      $('.filter-date .input-date-range').data('daterangepicker').setStartDate($startdateURLParam);
      $('[name="monitor-filter-startdate"]').val($startdateURLParam);
    }

    //FILTER over URL endDate
    var $enddateURLParam = getUrlParameter('cms_enddate');
    if( $enddateURLParam != undefined && $enddateURLParam != ''){
      $('.filter-date .input-date-range').data('daterangepicker').setEndDate($enddateURLParam);
      $('[name="monitor-filter-enddate"]').val($enddateURLParam);
    }

    //FILTER over URL endDate
    var $vCategoryURLParam = getUrlParameter('cms_v_category');
    if( $vCategoryURLParam != ''){
       $('[name="monitor-filter-vorhaben-category"]').val($vCategoryURLParam);
       $('[name="monitor-filter-vorhaben-category"]').val('bbplg');
    }
    //FILTER over URL endDate
    var $vSubCategoryURLParam = getUrlParameter('cms_v_subcategory');
    if( $vSubCategoryURLParam != ''){
       $('[name="monitor-filter-vorhaben-subcategory"]').val($vSubCategoryURLParam);
    }
    if( $vCategoryURLParam != '' && $vSubCategoryURLParam != ''){
      $('.filter-vorhaben [data-category="'+$vCategoryURLParam+'"][data-subcategory="'+$vSubCategoryURLParam+'"]').addClass('active-filter');
      $('.filter-vorhaben [data-category="'+$vCategoryURLParam+'"][data-subcategory="'+$vSubCategoryURLParam+'"]').children('span').removeClass('badge--secondary');
      $('.filter-vorhaben [data-category="'+$vCategoryURLParam+'"][data-subcategory="'+$vSubCategoryURLParam+'"]').children('span').addClass('badge--default');
    }


    //FILTER CATEGORY/SUBCATEGORY
    $('.monitor-navigation [data-category]').click(function(e){
      e.preventDefault();

      $category = $(this).data('category');
      $subcategory = $(this).data('subcategory');
      $(this).closest('.monitor-navigation').children('[name="monitor-filter-category"]').val($category);
      $(this).closest('.monitor-navigation').children('[name="monitor-filter-subcategory"]').val($subcategory);

      //reset Media Filter
      $('[name="monitor-filter-media"]').val("");
      $('.filter-media').find('.active').removeClass('active');

      //send AJAX to update page with given filter parameters
      allFilters();


      $('.monitor-navigation [data-category]').removeClass('active');
      if( $(this).data('subcategory') ) {
        //click subcategory
        $(this).closest('.accordion').siblings().find('.collapse').removeClass('show');
        $(this).closest('.accordion').siblings().find('[data-toggle="collapse"]').addClass('collapsed');
      }else{
        $(this).parent().children('.accordion').find('.collapse').removeClass('show');
        $(this).parent().children('.accordion').find('[data-toggle="collapse"]').addClass('collapsed');
      }
      $(this).addClass('active');
    });


    //FILTER VORHABEN CATEGORY/SUBCATEGORY
    $('.filter-vorhaben [data-category]').click(function(e){
      e.preventDefault();

      if( $(this).closest('.filter-vorhaben').hasClass('filter-vorhaben-labels') ){
        if( $(this).hasClass('active-filter') ){
          $(this).removeClass('active-filter');
          $(this).children('span').removeClass('badge--default');
          $(this).children('span').addClass('badge--secondary');
        }else{
          if( $(this).data('category') == 'all' ){
            $('.labels-wrapper > a').removeClass('active-filter');
            $('.labels-wrapper > a > span').removeClass('badge--default');
            $('.labels-wrapper > a > span').addClass('badge--secondary');
          }else{
            $('.labels-wrapper > a[data-category="all"]').removeClass('active-filter');
            $('.labels-wrapper > a[data-category="all"] > span').removeClass('badge--default');
            $('.labels-wrapper > a[data-category="all"] > span').addClass('badge--secondary');
          }
          $(this).addClass('active-filter');
          $(this).children('span').removeClass('badge--secondary');
          $(this).children('span').addClass('badge--default');
        }
        //collect all categories and write them in to the input filed commaseperated
        var $final_v_category = '';
        var $final_v_subcategory = '';
        $('.filter-vorhaben-labels .active-filter').each(function(){
          $v_category = $(this).data('category');
          $v_subcategory = $(this).data('subcategory');
          if($final_v_category == ''){
            $final_v_category = $v_category;
          }else{
            $final_v_category = $final_v_category+','+$v_category;
          }
          if($final_v_subcategory == ''){
            $final_v_subcategory = $v_subcategory;
          }else{
            $final_v_subcategory = $final_v_subcategory+','+$v_subcategory;
          }
        });
        $('[name="monitor-filter-vorhaben-category"]').val($final_v_category);
        $('[name="monitor-filter-vorhaben-subcategory"]').val($final_v_subcategory);

        //send AJAX to update page with given filter parameters
        allFilters();

      }
      if( $(this).closest('.filter-vorhaben').hasClass('filter-vorhaben-list') ){
        $v_category = $(this).data('category');
        $v_subcategory = $(this).data('subcategory');
        $('[name="monitor-filter-vorhaben-category"]').val($v_category);
        $('[name="monitor-filter-vorhaben-subcategory"]').val($v_subcategory);
        

        //toDO
        //send AJAX to update page with given filter parameters
        allFilters();

        //if it is a select dropdown link
        $('.filter-vorhaben [data-category]').removeClass('active');
        if( $(this).data('subcategory') ) {
          //click subcategory
          $('.filter-vorhaben .active-filter').removeClass('active-filter');
          $(this).closest('.accordion').siblings().find('.collapse').removeClass('show');
          $(this).closest('.accordion').siblings().find('.collapse-title > a').addClass('collapsed').removeClass('active').attr('data-toggle','collapse');

          $(this).closest('.accordion').find('.collapse-title > a').addClass('active-filter').removeAttr('data-toggle');
          $(this).closest('.collapse').addClass('active-filter');
          $(this).addClass('active-filter');
        }else{
          $('.filter-vorhaben .active-filter').removeClass('active-filter');
          $('.filter-vorhaben .collapse-title > a').removeClass('active').attr('data-toggle','collapse');
          $(this).parent().children('.accordion').find('.collapse').removeClass('show');
          $(this).parent().children('.accordion').find('[data-toggle="collapse"]').addClass('collapsed');
        }
        $(this).addClass('active');
      }
    });

    $('.accordion .collapse-title > a+.svg-icon').click(function(e){
       e.preventDefault();
       resetFilterVorhaben();
    });

    //FILTER ORDER
    $('.monitor-top-panel [data-filter]').click(function(e){
      e.preventDefault();
      $sort = $(this).data('filter');

      //is ASCDESC field
      if( $(this)[0].hasAttribute('data-ascdesc') ) {
        $ascdesc = $(this).data('ascdesc');
        if($ascdesc == 'asc') {
          $ascdesc = 'desc';
        }else {
          $ascdesc = 'asc';
        }
        $(this).data('ascdesc',$ascdesc);
        $(this).removeClass('asc');
        $(this).removeClass('desc');
        $(this).addClass($ascdesc);
      }else{
        $ascdesc = false;
      }
      $(this).parent().children('[name="monitor-filter-sort"]').val($sort);
      $(this).parent().children('[name="monitor-filter-ascdesc"]').val($ascdesc);



      //toDO
      //send AJAX to update page with given filter parameters
      allFilters();
      

      $('.monitor-top-panel [data-filter]').removeClass('active');
      $(this).addClass('active');
    });


    //FILTER MEDIA
    $('.monitor-filter-panel .filter-media a').click(function(e){
      e.preventDefault();
      $filterMedia = $(this).data('filter');
      $('.monitor-filter-panel [name="monitor-filter-media"]').val($filterMedia);

      //reset Navigation Filters
      $('.monitor-navigation').find('.active').removeClass('active');
      $('.monitor-navigation').find('[data-category="all"]').addClass('active');
      $('.monitor-navigation').find('[data-toggle="collapse"]').addClass('collapsed');
      $('.monitor-navigation').find('.collapse').removeClass('show');
      $('[name="monitor-filter-category"]').val('all');
      $('[name="monitor-filter-subcategory"]').val('all');

      //send AJAX to update page with given filter parameters
      allFilters();
      

      $('.monitor-filter-panel [data-filter]').removeClass('active');
      $(this).addClass('active');

    });

    //FILTER START/END DATE
    $('.filter-date .input-date-range').on('apply.daterangepicker', function(ev, picker) {
        $startdate = picker.startDate.format('DD/MM/YYYY');
        $enddate = picker.endDate.format('DD/MM/YYYY');
        //do something, like clearing an input
        $('[name="monitor-filter-startdate"]').val($startdate);
        $('[name="monitor-filter-enddate"]').val($enddate);
        showDateXicon();

        //toDO
        //send AJAX to update page with given filter parameters
        allFilters();
    });

    function showDateXicon() {
      $('.filter-date .input-date-range + .svg-icon').show();
    }
    function hideDateXicon() {
      $('.filter-date .input-date-range + .svg-icon').hide();
    }

    $('.filter-date .input-date-range + .svg-icon').click(function(e){
        e.preventDefault();
        resetDatepicker();
    });

    function resetDatepicker(){
        //change the selected date range of that picker
        $startdate = $('.filter-date .input-date-range').attr('data-startDate');
        $enddate = $('.filter-date .input-date-range').attr('data-endDate');
        $('.filter-date .input-date-range').data('daterangepicker').setStartDate($startdate);
        $('.filter-date .input-date-range').data('daterangepicker').setEndDate($enddate);
        $('[name="monitor-filter-startdate"]').val($startdate);
        $('[name="monitor-filter-enddate"]').val($enddate);
        hideDateXicon();
    }

    function resetFilterVorhaben() {
      $('.filter-vorhaben .collapse-title > a.active').removeClass('active');
      $('.filter-vorhaben .collapse-title > a.active-filter').attr('data-toggle','collapse').removeClass('active').addClass('collapsed');
      $('.filter-vorhaben .active-filter').removeClass('active-filter');
      $('.filter-vorhaben .active').removeClass('active');
      $('.filter-vorhaben .collapse.show').removeClass('show');
    }

    function resetSearchFilterVorhaben() {
      $('.filter-search').find('[name="filtersearch"]').val('');
    }

    function resetSortFilterVorhaben() {
      $('.monitor-top-panel').find('[data-filter]').removeClass('active'); 
      $('.monitor-top-panel').find('[data-filter="date"]').addClass('active');
      $('[name="monitor-filter-sort"]').val('date');
      $('[name="monitor-filter-ascdesc"]').val('desc'); 
    }
    function resetNavigationFilterVorhaben() {
      $('.monitor-navigation').find('[data-toggle="collapse"]').addClass('collapsed');
      $('.monitor-navigation').find('.collapse.show').removeClass('show');
      $('.monitor-navigation').find('.active').removeClass('active');
      $('.monitor-navigation').find('.active').removeClass('active');
      $('.monitor-navigation').find('[data-category="all"]').addClass('active');
      $('.monitor-navigation').find('[name="monitor-filter-category"]').val('all');
      $('.monitor-navigation').find('[name="monitor-filter-subcategory"]').val('');
    }


    //RESET ALL FILTERS IN PANEL
    $('.monitor-filter-panel .resetFilters').click(function(e){
      e.preventDefault();
      
      resetDatepicker();//reset datepicker
      resetFilterVorhaben();//reset vorhaben filters
      resetSearchFilterVorhaben();//reset search filter
      $('.monitor-filter-panel .filter-media .filter-item .active').removeClass('active');//reset media filters
      resetSortFilterVorhaben();//reset sort filters
      resetNavigationFilterVorhaben();//reset navigation filters
      $('.filter-vorhaben-labels').find('.badge').removeClass('badge--default');
      $('.filter-vorhaben-labels').find('.badge').addClass('badge--secondary');


      gatherFilters();
      console.log('Filters after Clear:');
      allFilters();
    });


    //allFilters General Function
    function allFilters() {
      //top panel
      $category = $('[name="monitor-filter-category"]').val();
      $subcategory = $('[name="monitor-filter-subcategory"]').val();
      //filter panel
      $filterMedia = $('[name="monitor-filter-media"]').val();
      $v_category = $('[name="monitor-filter-vorhaben-category"]').val();
      $v_subcategory = $('[name="monitor-filter-vorhaben-subcategory"]').val();
      $startdate = $('[name="monitor-filter-startdate"]').val();
      $enddate = $('[name="monitor-filter-enddate"]').val();
      $search = $('[name="filtersearch"]').val();
      $searchPage = $('.more-search').attr('data-page');
      $monitorPage = $('.more-monitor').attr('data-page');

      //navigaiton panel
      $sort = $('[name="monitor-filter-sort"]').val();
      $ascdesc = $('[name="monitor-filter-ascdesc"]').val();
      if($search == '' && $sort.startsWith("score")){
        $sort = 'dateOfIssue_dt';
        $ascdesc = 'desc';
      }

      if(!$pageFlag){
        $searchPage = 1;
        $monitorPage = 1;
      }


      var filters = {
            cms_sort: $sort,
            cms_sort_ascdesc: $ascdesc,
            cms_category: $category,
            cms_subcategory: $subcategory,
            cms_filterMedia: $filterMedia,
            cms_v_category: $v_category,
            cms_v_subcategory: $v_subcategory,
            cms_startdate: $startdate,
            cms_enddate: $enddate,
            cms_search: $search,
            cms_searchPage: $searchPage,
            cms_monitorPage: $monitorPage,
            cms_pageFlag: $pageFlag
       };

       var targetURL = $('[name="target-monitor-filter-url"]').val();


      //toDO
      //send AJAX to update page with given filter parameters
      $.ajax({ 
          type: 'GET', 
          url: targetURL, 
          data: filters, 
          dataType: 'json',
          success: function (data) { 

            $('.monitor-search-title').show();

            //keep current scroll position
            var currentOffset = $(document).scrollTop();


            //initial content
            if(data.search.page < 2){
              $('.monitor-search-results-counter').html(data.search.counter);//update counter
              $('.monitor-search-results-container').html(data.search.content);//update content
              $('.more-search').attr('data-page','1');//reset more btn
              $searchPage = '1';
            }
            if(data.monitor.page < 2){
              $('.monitor-results-counter').html(data.monitor.counter);//update counter
              $('.monitor-results-container').html(data.monitor.content);//update content
              $('.more-monitor').attr('data-page','1');//reset more btn
              $monitorPage = '1';
            }
            if(data.search.page < 2 && data.monitor.page < 2){
              $totalCount = parseInt(data.search.counter) + parseInt(data.monitor.counter);
              // $('.monitorCounter').html($totalCount);//update all other counters
            }
            //append content
            if(data.search.page > 1){
              $('.monitor-search-results-container').find('.noResults').remove();
              $('.monitor-search-results-container').append(data.search.content);//append content
            }
            if(data.monitor.page > 1){
              $('.monitor-results-container').find('.noResults').remove();
              $('.monitor-results-container').append(data.monitor.content);//update content
            }
            if(data.search.isLast == true ){
              $('.more-search').hide();
            }else {
              $('.more-search').show();
            }
            if(data.monitor.isLast == true ){
              $('.more-monitor').hide();
            }else{
              $('.more-monitor').show();
            }
            if(data.search.counter == 0){
              $('.monitor-search-results-container').html('<p class="text-center noResults">Keine Treffer</p>');//append content
            }
            if(data.monitor.counter == 0){
              $('.monitor-results-container').html('<p class="text-center noResults">Keine Treffer</p>');//append content
            }

            //keep the current scroll position
            $(document).scrollTop(currentOffset);

            BNETZA.mediathek.videoLink();
            $('.list-item--box').matchHeight();
            stickyNav.update();
            setTimeout(function(){
              $('.list-item--box').matchHeight();
              stickyNav.update();
            },500);
            setTimeout(function(){
              $('.list-item--box').matchHeight();
              stickyNav.update();
            },1000);
            setTimeout(function(){
              $('.list-item--box').matchHeight();
              stickyNav.update();
            },1500);




          },
          error: function (result) {
              console.log(result);
          }
      });

      //reset page Flags
      $pageFlag = false;

    }

    
    //gather all active Filters
    function gatherFilters() {
      $category = $('.monitor-navigation  [data-category].active').data('category');
      $subcategory = $('.monitor-navigation  [data-subcategory].active').data('subcategory');
      $sort = $('.monitor-top-panel [data-filter].active').data('filter');
      $filterMedia = $('.monitor-filter-panel .filter-media [data-filter].active').data('filter');
      $v_category = $('.filter-vorhaben  [data-category].active-filter').data('category');
      $v_subcategory = $('.filter-vorhaben  [data-subcategory].active-filter').data('subcategory');
      $('.monitor-navigation [name="monitor-filter-category"]').val($category);
      $('.monitor-navigation [name="monitor-filter-subcategory"]').val($subcategory);
      $('.monitor-top-panel [name="monitor-filter-sort"]').val($sort);
      $('.monitor-filter-panel [name="monitor-filter-media"]').val($filterMedia);
      $('.filter-vorhaben [name="monitor-filter-vorhaben-category"]').val($v_category);
      $('.filter-vorhaben [name="monitor-filter-vorhaben-subcategory"]').val($v_subcategory);
    }

    //initialize filter inputs
    $(document).ready(function(){
      if( $('[name="monitor-filter-category"]').length > 0 && $('[name="monitor-filter-sort"]').length > 0  ){
        gatherFilters();

        if(urlHasParams){
          allFilters();
        }
      }
    });



    //search
    $('[name="filtersearch"]').change(function(){
      console.log('change');
      allFilters();
    });



    //more results
    $('.more-search').click(function(){
      $searchPage = parseInt($(this).attr('data-page'));
      $searchPage = $searchPage + 1;
      $(this).attr('data-page',$searchPage);
      $pageFlag = 'search';
      allFilters();
    });
    $('.more-monitor').click(function(){
      $monitorPage = parseInt($(this).attr('data-page'));
      $monitorPage = $monitorPage + 1;
      $(this).attr('data-page',$monitorPage);
      $pageFlag = 'monitor';
      allFilters();
    });


    window.addEventListener("resize", () => {
      console.log('resize');
      stickyNav.update();
    });




   
  }
};

export default monitorNavigation;