/* eslint-disable import/no-mutable-exports */
var videoTeaser = {
  init: function () {


  	




  }
};

export default videoTeaser;