/* eslint-disable import/no-mutable-exports */
var progress = {
  init: function () {


    function calculateProgressBar(){
      if( $('.progress').length > 0 ){
      	$('.progress').each(function(){
      		var total = 0;
      		$(this).children('.progress-bar').each(function(){
      			total = total + $(this).data('number');
      		});
      		$(this).children('.progress-bar').each(function(){
      			var number = $(this).data('number');
      			var width = (number*100)/total;
      			$(this).attr('style','width:'+width+'%');
      		});
      	});
      }

    }

    calculateProgressBar();



  }
};

export default progress;