/* eslint-disable import/no-mutable-exports */
var overviewLeftPanel = {
  init: function (stickybits) {
  	//migrated to overview-top-nav
//  	const stickyNav  = stickybits('.overview-left-panel', { useStickyClasses: true , stickyBitStickyOffset: 240 });
//
//
//  	window.addEventListener('resize', () => {
//      stickyNav.update();
//    });
//
//    window.addEventListener('hashchange', () => {
//      stickyNav.update();
//    });

  }
};

export default overviewLeftPanel;