/* eslint-disable import/no-mutable-exports */
var menuFlyoutMobile = {
  init: function () {
  	
    



    //resize between viewports
      var resizeTimer = false;
      $(window).on('resize', function(e) {
        if( !resizeTimer ) {
        $(window).trigger('resizestart');   
        }
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
        resizeTimer = false;
        $(window).trigger('resizeend');
        }, 750);
      }).on('resizestart', function(){
      }).on('resizeend', function(){
        if( $(window).width() < 992 ) {
          $('.menu-flyout-mobile').css('height',window.innerHeight - 82);
          if( $('.vorhaben-mobile-menu-panel-filter').length > 0 ){
            $('.vorhaben-mobile-menu-panel-filter').css('height',window.innerHeight - 82);
          }
          if( $('.monitor-mobile-menu-panel-filter').length > 0 ){
            $('.monitor-mobile-menu-panel-filter').css('height',window.innerHeight - 82);
          }
        }else{
        }
      });




    $('.menu-flyout-mobile .menu-trigger').click(function(e){
      e.preventDefault();

      if( $(this).closest('.content').hasClass('active') ){
        $(this).closest('.content').removeClass('active');
        $('.menu-flyout-mobile .content').removeClass('inactive');
      }else{
        $('.menu-flyout-mobile .content').removeClass('active');
        $('.menu-flyout-mobile .content').addClass('inactive');
        $(this).closest('.content').removeClass('inactive');
        $(this).closest('.content').addClass('active');
      }
       
    });


  }
};

export default menuFlyoutMobile;