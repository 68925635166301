/* eslint-disable import/no-mutable-exports */
var footer = {
  init: function () {

  	var scrollposNewsletter = $( window ).height()+($( window ).height()*0.05);
  	var scrollposSocial = $( window ).height()+($( window ).height()*0.10);

  	$(document).ready(function() {
		//Create all your animations in a function. You define what you want to animate and how.
		function bottomAnimations(){
			$("#bottom-wrapper--newsletter .newsletter-block").scrollMation({
				action : 'flyIn',
				scrollPos: scrollposNewsletter,
				startPos : -300,
				endPos: 0
			});
			$("#bottom-wrapper--social .social").scrollMation({
				action : 'flyIn',
				scrollPos: scrollposSocial,
				startPos : 300,
				endPos: 0
			});
			$("#bottom-wrapper--social:before").scrollMation({
				action : 'flyIn',
				scrollPos: scrollposSocial,
				startPos : 300,
				endPos: -100
			});
		}
		//Initiate the animations so that they apply before the user starts scrolling.
		bottomAnimations();
		//Add the scroll event and call the animations function.
		$(window).scroll(function(){
			bottomAnimations();
		});
	});

  }
};

export default footer;