/* eslint-disable import/no-mutable-exports */
var overviewTopPanel = {
  init: function (stickybits) {

    const stickyNav  = stickybits('.overview-left-panel', { useStickyClasses: true , stickyBitStickyOffset: 240 });


    window.addEventListener('resize', () => {
      stickyNav.update();
    });

    window.addEventListener('hashchange', () => {
      stickyNav.update();
    });


  	$('.overview-top-panel .menu-opener').click(function(e){
      e.preventDefault();
  		console.log('menu-opener');
      var target = $(this).data('target');
      $('#'+target).addClass('active');
      $('.overview-top-panel--basis').addClass('hidden');

  	});

    $('.overview-top-panel .close-panel').click(function(e){
      e.preventDefault();
      console.log('menu-close');
      //$(this).closest('.overview-top-panel').removeClass('active');
      //$('.overview-top-panel').removeClass('hidden');
      closePanel();
    });

    function closePanel(){
      $('.overview-top-panel').removeClass('active');
      $('.overview-top-panel').removeClass('hidden');
       stickyNav.update();
    }

    if( $('.overview-top-panel').length > 0 ) {
      $('.main__inner > .container').on('click',function(){
        closePanel();
      });
      $('.main-navi .nav').on('click',function(){
        closePanel();
      });
      $('.header__inner').on('click',function(){
        closePanel();
      });
    }
  

    $('.detailsSwitch .form-radio-input').click(function(){
      //toDo
      //trigger function for Details On/Off
      var curVal = $(this).val()
      if(curVal == 'details-on'){
        $('.list-item--accordion').each(function(){
          $(this).children('.collapse').collapse('show');
        });
      }
      if(curVal == 'details-off'){
        $('.list-item--accordion').each(function(){
          $(this).children('.collapse').collapse('hide');
        });
      }

      stickyNav.update();
      setTimeout(function(){
        stickyNav.update();

      },500);

    })


  }
};

export default overviewTopPanel;