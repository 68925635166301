/* eslint-disable import/no-mutable-exports */
var overviewRightPanel = {
  init: function (stickybits, selectpicker, BNETZA) {
  	//not Sticky for right panel
  	// stickybits('.overview-right-panel', { useStickyClasses: true , stickyBitStickyOffset: 310 });


  	$('.filter-reset').click(function(e){
      e.preventDefault();
      $('.overview-right-panel .filter-container input').each(function(){
        $(this).prop('checked', false);
      })
      $('.overview-right-panel .filter-container select').each(function(){
         $.each( $(this).children("option:selected") , function(){        
           $(this).prop("selected", false);
         });
       });
      var activeFilters  = '';
      var countFilters = 0;
      activeFilters = gatherAllFilters();
      countFilters = countActiveFilters();
      updateButtonText(countFilters);
      $(".bnetza-select").selectpicker("refresh");//reset multiple selects
      //trigger filter BTN
      $('.overview-right-panel .filter-submit').trigger('click');

  	});

  	$('.more-filter-trigger').click(function(){
  		$(this).parent('.filter-container').children('.hidden').removeClass('hidden');
  		$(this).addClass('hidden');
  	});


    function gatherAllFilters(){
      var activeFilters = $("#vorhabenFilterForm").serialize();
      return activeFilters;
    }

    function countActiveFilters(){
      var activeFiltersArray = $('#vorhabenFilterForm  :not(input[type=hidden])').serializeArray();

      var countFilters= activeFiltersArray.length;
      return countFilters;
    }

    function updateButtonText(countFilters){
      $('.vorhabenCounter').html(countFilters);//update all Counters
    }

    $('.overview-right-panel .filter-container input, .overview-right-panel .filter-container select').change(function(){
      var activeFilters  = '';
      var countFilters = 0;
      activeFilters = gatherAllFilters();
      countFilters = countActiveFilters();
      updateButtonText(countFilters);
    });



    //submit
    $('.overview-right-panel .filter-submit').click(function(e){
      e.preventDefault();
      var activeFilters  = '';
      var countFilters = 0;
      var targetURL = $("#vorhabenFilterForm").attr("action");
      activeFilters = gatherAllFilters();
      countFilters = countActiveFilters();


      //toDo send active Filter over AJAX request and GET new HTML
      
      $.ajax({ 
          type: 'POST', 
          url: targetURL, 
          data: activeFilters, 
          dataType: 'html',
          success: function (data) { 
            //initial content
            $('#vorhabenTarget').html(data); //update content  
            BNETZA.progress.init();
          },
          error: function (result) {
              console.log(result);
          }
      });


    });


    $(document).ready(function(){
      if( $('.overview-right-panel').length > 0  ){
        var activeFilters  = '';
        var countFilters = 0;
        //initialize
        activeFilters = gatherAllFilters();
        countFilters = countActiveFilters();
        updateButtonText(countFilters);
      }
    });


  }
};

export default overviewRightPanel;