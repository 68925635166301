/* eslint-disable import/no-mutable-exports */
var defaultMobilePanel = {
  init: function () {


  	function openMobileDefaultPanel(){
	  	$('.default-mobile-menu-panel').addClass('active');
  	}
  	function closeMobileDefaultPanel(){
	  	$('.default-mobile-menu-panel').removeClass('active');
  	}
  	if( $('.default-mobile-menu-panel').length > 0 && $('.panel-navigation').length > 0 ) {
	  	if( $(window).width() < 992 ) {
  			switchToMobileMode();
  		}

	  	function switchToMobileMode(){
	  		if( $(window).width() < 992 ) {
	  			if ($('.main__inner > .container:first-child > .row:first-child > .col:first-child .panel-navigation').length > 0 ) {
  	console.log('huhu');	
			  		$('.default-mobile-menu-panel').append( $('.panel-navigation') );
	
				  	//on trigger open mobile-map-mpanel
				  	$('.default-mobile-menu-panel-trigger .mobile-panel-trigger').click(function(e){
				  		e.preventDefault();
				  		openMobileDefaultPanel();
				  	});
				  	//on trigger close mobile-map-panel
				  	$('.default-mobile-menu-panel .mobile-panel-close').click(function(e){
				  		e.preventDefault();
				  		closeMobileDefaultPanel();
				  	});
			  	}
		  	}
	  	}
	  	function switchToDesktopMode(){
		  	if( $(window).width() > 991 ) {
	  			if ($('.default-mobile-menu-panel .panel-navigation').length > 0 ) {
	  				$('.main__inner > .container:first-child > .row:first-child > .col:first-child').prepend( $('.panel-navigation') );
	  			}
		  	}
	  	}

	  	//resize between viewports
	  	var resizeTimer = false;
	  	$(window).on('resize', function(e) {
	  	  if( !resizeTimer ) {
	  		$(window).trigger('resizestart');  	
	  	  }
	  	  clearTimeout(resizeTimer);
	  	  resizeTimer = setTimeout(function() {
	  		resizeTimer = false;
	  		$(window).trigger('resizeend');
	  	  }, 750);
	  	}).on('resizestart', function(){
	  	}).on('resizeend', function(){
		  	if( $(window).width() < 992 ) {
	  			switchToMobileMode();
	  		}else{
	  			switchToDesktopMode();
	  		}
	  	});

  	}
  	

  }
};

export default defaultMobilePanel;